import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import {DataService} from "../services/data.service";
import {ToastrService} from "ngx-toastr";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService, private toastr: ToastrService,) {
  }

  /**
   * Interceptor to handle HTTP requests and responses.
   * @param request HTTP request to be intercepted.
   * @param next HTTP handler to handle the intercepted request.
   * @returns An observable of HTTP events.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: () => this.initService(),
        error: (err: HttpErrorResponse) => {
          if ([401, 403].includes(err.status)) {
            this.dataService.logout(false);
          }
          const error = err.message || err.status;
          return throwError(error);
        },
      })
    );
  }


  /**
   * Initializes the service and checks the user's network connection speed. If the connection speed is slow,
   * it displays a message to the user.
   */
  initService = () => {
    const conn = (navigator as any).connection;
    if (conn) {
      const effectiveType = conn.effectiveType;
      if (effectiveType === 'slow-2g' || effectiveType === '2g') {
        this.toastr.info('Slow Internet Connection.');
      }
    }
  }
}
