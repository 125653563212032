import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CommonTypes} from '../utilities/models';
import {LocalStorageService} from "../services/local-storage.service ";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const _accessToken = this.localStorageService.getItem("access_token");
    if (_accessToken) {
      this.router.navigate(['/public/posts'], {queryParams: {type: CommonTypes.NOTICE}});
      return false;
    }

    return true;
  }
}
