import {Component, OnInit} from '@angular/core';
import {InteractionService} from "../../services/interaction.service";
import {CommonTypes} from "../../utilities/models";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute, RouterLink, RouterOutlet} from "@angular/router";
import {environment} from "../../../environments/environment";
import {AttachmentService, OrganizationService} from "../../services/api.service";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-static',
  templateUrl: './static.component.html',
  styleUrls: ['./static.component.scss'],
  imports: [
    NgForOf,
    NgClass,
    RouterLink,
    TranslateModule,
    NgIf,
    RouterOutlet
  ],
  standalone: true
})
export class StaticComponent implements OnInit {

  commonTypes = CommonTypes;
  postType: CommonTypes = this.commonTypes.NOTICE;
  version: string = environment.version;
  themeList: any;
  logo: any;

  constructor(private interactionService: InteractionService,
              private route: ActivatedRoute,
              private meta: Meta,
              private organizationService: OrganizationService,
              private attachmentService: AttachmentService,
              private title: Title) {

    this.meta.addTags([
      {name: 'description', content: 'Public Notices'},
      {name: 'author', content: 'PublicNotices'},
      {name: 'keywords', content: 'PN, Public Notices'}
    ]);

    this.title.setTitle('Public Notices');

    this.route.queryParams.subscribe(params => {
      if (params['type'] !== undefined) {
        this.postType = params['type'];
      }
    });

  }

  ngOnInit(): void {
    this.interactionService.setPostType(this.postType);
    this.loadTheme();
  }

  setTab(postType: CommonTypes) {
    this.postType = postType;
    this.interactionService.setPostType(postType);
  }

  /**
   * Loads the theme settings and applies the corresponding styles to the UI.
   * It fetches the organization data and checks which post types are enabled for the organization,
   and sets the current post type accordingly.
   * Then it sets the primary color, primary text color, primary background color and font family
   by setting CSS variables on the root element.
   * Finally, it fetches the logo image for the organization and sets it to the corresponding property.
   */
  loadTheme() {
    this.organizationService.own().subscribe(result => {
      this.themeList = result;
      this.postType = this.themeList?.postCategory;
      document.documentElement.style.setProperty('--dynamic-primary-color', this.themeList?.organizationWebSiteStyleSummaryProjection?.primaryColor);
      document.documentElement.style.setProperty('--dynamic-primary-text-color', this.themeList?.organizationWebSiteStyleSummaryProjection?.primaryTextColor);
      document.documentElement.style.setProperty('--dynamic-primary-background-color', this.themeList?.organizationWebSiteStyleSummaryProjection?.primaryBodyColor);
      document.documentElement.style.setProperty('--dynamic-font-family', this.themeList?.organizationWebSiteStyleSummaryProjection?.fontFamily);
      this.attachmentService.getOrganizationAttachment(this.themeList?.orgId, 'LIGHT').subscribe(value => {
        const reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = (event:any) => {
          this.logo = event.target.result;
        };
      });
    });
  }
}
