<div class="public container">
  <div class="header">
    <div class="company-info">
      <img alt="" class="icon"
           [src]="logo">
    </div>

    <div class="header-menus">
      @for (item of themeList?.menuItems; track item.link) {
        @if (item?.menuItemType === 'HEADER') {
          <h1 class="title">
            <a [href]="item?.link" target="_blank">{{ item?.text }}</a>
          </h1>
        }
      }
    </div>

  </div>


  <div class="btn-row">
    <button [ngClass]="{'tap-button-focus': postType === commonTypes.NOTICE}" (click)="setTab(postType)"
            [queryParams]="{type:postType}" [routerLink]="'/static/posts'">
      <img
        alt="" src="assets/landing-page/Iconly_Light_Document (1).svg">

      <span>{{ 'HEADER.TAB_BUTTON_NOTICES' | translate }}</span>
    </button>
    <!--    <button [ngClass]="{'tap-button-focus': postType === commonTypes.JOB}" (click)="setTab(commonTypes.JOB)"-->
    <!--            *ngIf="themeList?.viewJobs"-->
    <!--            [queryParams]="{type:commonTypes.JOB}" [routerLink]="'/static/posts'">-->
    <!--      <img alt=""-->
    <!--           src="assets/landing-page/Iconly_Light_Bag 4.svg">-->
    <!--      <span>{{'HEADER.TAB_BUTTON_JOBS' | translate}}</span>-->
    <!--    </button>-->
    <!--    <button [ngClass]="{'tap-button-focus': postType === commonTypes.EVENT}" (click)="setTab(commonTypes.EVENT)"-->
    <!--            *ngIf="themeList?.viewEvents"-->
    <!--            [queryParams]="{type:commonTypes.EVENT}" [routerLink]="'/static/posts'">-->
    <!--      <img-->
    <!--        alt="" src="assets/landing-page/Iconly_Light_Calendar (1).svg">-->
    <!--      <span>{{'HEADER.TAB_BUTTON_EVENTS' | translate}}</span>-->
    <!--    </button>-->
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <div class="footer mobile-footer">

    <div class="footer-menus">
      @for (item of themeList?.menuItems; track item.link) {
        @if (item?.menuItemType === 'FOOTER') {
          <h1 class="title">
            <a [href]="item?.link" target="_blank">{{ item?.text }}</a>
          </h1>
        }
      }
    </div>
  </div>

</div>

