import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { DataService } from "./services/data.service";
import { environment } from "../environments/environment";
import {Router, RouterOutlet} from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import {LocalStorageService} from "./services/local-storage.service ";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isLogged: boolean = false;

  constructor(
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isLogged = this.dataService.getLoginStatus();

    const activeLang = this.localStorageService.getItem('ACTIVE_LANG');
    const browserLang = navigator.language;

    if (browserLang.includes('es')) {
      dataService.setLanguage('sp');
    } else if (browserLang.includes('en')) {
      dataService.setLanguage(this.isLogged && activeLang ? activeLang : 'en');
    }

    if (isPlatformBrowser(this.platformId)) {
      // Access window only in the browser (not on the server)
      const origin = window.location.hostname;

      if (environment.origin !== origin && origin !== 'localhost') {
        this.router.navigate(['/static']);
      }
    }
  }
}
