import {Routes} from '@angular/router';
import {AuthGuard} from "./guards/auth.guard";
import {LandingComponent} from "./layouts/landing/landing.component";
import {PublicComponent} from "./layouts/public/public.component";
import {StaticComponent} from "./layouts/static/static.component";

export const routes: Routes = [
  {
    // Landing page
    path: '',
    component: LandingComponent,
    canActivate: [AuthGuard]
  },
  {
    // viewer web. this contains the header and router-outlet
    path: 'public',
    component: PublicComponent,
    loadChildren: () => import('./layouts/public/public.routes').then(m => m.routes)
  },
  {
    // client web. this contains the header and router-outlet
    path: 'static',
    component: StaticComponent,
    loadChildren: () => import('./layouts/static/static.routes').then(m => m.routes)
  },
  {
    path: '**',
    redirectTo: 'public',
    pathMatch: 'full'
  }
];
