import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {InteractionService} from "../../services/interaction.service";
import {CommonTypes} from "../../utilities/models";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router";
import {environment} from "../../../environments/environment";
import {DataService} from "../../services/data.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {isPlatformBrowser, NgClass, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  imports: [
    NgClass,
    RouterLink,
    TranslateModule,
    RouterOutlet,
    NgOptimizedImage
  ],
  standalone: true
})
export class PublicComponent implements OnInit, AfterViewInit {

  isLogged: boolean = false;
  commonTypes = CommonTypes;
  TERMS_CONDITIONS = CommonTypes.TERMS_CONDITIONS;
  PRIVACY_POLICY = CommonTypes.PRIVACY_POLICY;
  postType!: any;
  loggedName!: string;
  version: string = environment.version;
  isShowNotification: boolean = false;

  constructor(private interactionService: InteractionService,
              private router: Router,
              private route: ActivatedRoute,
              private dataService: DataService,
              private jwtHelperService: JwtHelperService,
              private readonly cdr: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: object,
              private meta: Meta,
              private title: Title) {
    if (isPlatformBrowser(this.platformId)) {
      this.isLogged = this.dataService.getLoginStatus();

      let activeLang = localStorage.getItem('ACTIVE_LANG');
      if (activeLang !== null) {
        this.dataService.changeLang(activeLang);
      }

      this.setDisplayName();

      this.meta.addTags([
        {name: 'description', content: 'Public Notices'},
        {name: 'author', content: 'PublicNotices'},
        {name: 'keywords', content: 'PN, Public Notices'}
      ]);
      this.title.setTitle('Public Notices');

      this.route.queryParams.subscribe(params => {
        if (params['type'] !== undefined) {
          this.postType = params['type'];
          this.isShowNotification = !window.location.href.includes('/posts/notifications') && this.isLogged;
        }
      });
    }
  }

  ngOnInit(): void {
    this.interactionService.apiLoginUpdate.subscribe(emit => {
      if (emit) {
        this.isLogged = this.dataService.getLoginStatus();
        this.setDisplayName();
      }
    });
  }

  setDisplayName() {
    let token = localStorage.getItem('access_token');
    if (token !== undefined && token !== null) {
      let visitorData = this.jwtHelperService.decodeToken(token);
      this.loggedName = visitorData['firstName'] + ' ' + visitorData['lastName'][0] + '.';
    }
  }


  goLandingPage() {
    if (!this.isLogged) {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    this.isShowNotification = !window.location.href.includes('/posts/notifications') && this.isLogged;
    this.cdr.detectChanges();
  }
}
