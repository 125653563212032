import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withInMemoryScrolling} from '@angular/router';

import {routes} from './app.routes';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi
} from "@angular/common/http";
import {provideNgxMask} from "ngx-mask";
import {provideClientHydration, withEventReplay} from "@angular/platform-browser";
import {provideToastr} from "ngx-toastr";
import {ErrorInterceptor} from "./guards/errorInterceptor";
import {JwtModule} from "@auth0/angular-jwt";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const provideTranslation = () => ({
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
    ),
    provideClientHydration(withEventReplay()),
    provideNgxMask(), provideToastr({
      positionClass: 'toast-bottom-right',
    }),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: () => {
            if (typeof window !== 'undefined' && window.localStorage) {
              return localStorage.getItem("access_token");
            }
            return null;
          },
          disallowedRoutes: [
            // add all public api endpoints here.
            "/dashboard-service/oauth/initiate",
            "/dashboard-service/oauth/token",
            "/dashboard-service/users/create"
          ],
        },
      }),
      [TranslateModule.forRoot(provideTranslation())]
    ),
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ]
};
