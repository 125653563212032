<div class="public container">
  <div class="header">
    <div class="company-info">
      <img (click)="goLandingPage()" [ngClass]="isLogged ? 'isLogged' : null" alt="" class="icon"
           ngSrc="assets/icons/LNLogo.svg" height="50" width="50">
    </div>

    <div class="btn-row">
      <a [queryParams]="{type:commonTypes.NOTICE}" [routerLink]="'/public/posts'" class="custom-a" href="">
        <button [ngClass]="{'tap-button-focus': postType === commonTypes.NOTICE}">
          <img alt="" ngSrc="assets/landing-page/Iconly_Light_Document (1).svg" height="24" width="24">
          <span>{{ 'HEADER.TAB_BUTTON_NOTICES' | translate }}</span>
        </button>
      </a>

      <a [queryParams]="{type:commonTypes.JOB}" [routerLink]="'/public/posts'" class="custom-a disabledNav" href="">
        <button [ngClass]="{'tap-button-focus': postType === commonTypes.JOB}">
          <img alt="" ngSrc="assets/landing-page/Iconly_Light_Bag 4.svg" height="24" width="24">
          <span>{{ 'HEADER.TAB_BUTTON_JOBS' | translate }}</span>
        </button>
      </a>

      <a [queryParams]="{type:commonTypes.EVENT}" [routerLink]="'/public/posts'" class="custom-a disabledNav" href="">
        <button [ngClass]="{'tap-button-focus': postType === commonTypes.EVENT}">
          <img alt="" ngSrc="assets/landing-page/Iconly_Light_Calendar (1).svg" height="24" width="24">
          <span>{{ 'HEADER.TAB_BUTTON_EVENTS' | translate }}</span>
        </button>
      </a>


    </div>

    <div class="user">
      <img (click)="postType = undefined" [routerLink]="'/public/posts/favorites'" alt="" class="svg"
           ngSrc="assets/public-page/Icons_Light_Heart.svg" height="24" width="24">
      <img (click)="postType = undefined" [routerLink]="'/public/settings'" alt="" class="svg"
           ngSrc="assets/public-page/Icons_Light_Setting.svg" height="24" width="24">
      <img (click)="postType = undefined" [routerLink]="'/public/posts/notifications'" alt=""
           class="svg"
           ngSrc="assets/public-page/Iconly_Light_Notification.svg" height="24" width="24">
      @if (isLogged) {
        <span class="display-name">{{ loggedName }}</span>
      } @else {
        <button (click)="postType = undefined" [routerLink]="'auth/login'"
                class="signin">{{ 'HEADER.SIGN_IN_BUTTON' | translate }}
        </button>
      }
    </div>
    @if (isLogged) {
      <div class="mobile-menu">
        <img [queryParams]="{type:commonTypes.NOTICE}" [routerLink]="'/public/posts'" alt=""
             ngSrc="assets/landing-page/Iconly_Light_Document (1).svg" height="24" width="24">
        <img [queryParams]="{type:commonTypes.JOB}" [routerLink]="'/public/posts'" alt=""
             ngSrc="assets/landing-page/Iconly_Light_Bag 4.svg" height="24" width="24">
        <img [queryParams]="{type:commonTypes.EVENT}" [routerLink]="'/public/posts'" alt=""
             ngSrc="assets/landing-page/Iconly_Light_Calendar (1).svg" height="24" width="24">

        <img [routerLink]="'/public/posts/favorites'" alt="" ngSrc="assets/public-page/Icons_Light_Heart.svg"
             height="24"
             width="24">
        <img [routerLink]="'/public/settings'" alt="" ngSrc="assets/public-page/Icons_Light_Setting.svg" height="24"
             width="24">
        <img [routerLink]="'/public/posts/notifications'" alt=""
             ngSrc="assets/public-page/Iconly_Light_Notification.svg"
             height="24" width="24">
      </div>
    }
  </div>
  <div class="content">
    <router-outlet></router-outlet>
    <!--    <notification-popup *ngIf="isShowNotification"></notification-popup>-->
  </div>

  <div class="footer mobile-footer">
    <div class="company-info">
      <h4>{{ 'FOOTER.TITLE' | translate }}</h4>
      <p>{{ 'FOOTER.CONTACT_EMAIL_ADDRESS' | translate }} &nbsp;&nbsp; {{ 'FOOTER.CONTACT_NO' | translate }}</p>
      <p>{{ 'FOOTER.ADDRESS' | translate }}, {{ 'FOOTER.CITY' | translate }},{{ 'FOOTER.STATE' | translate }}</p>
    </div>
    <div class="apps">
      <h4>{{ 'FOOTER.DOWNLOAD_APP' | translate }}</h4>
      <img alt="" src="assets/landing-page/appstore.png">&nbsp;
      <img alt="" src="assets/landing-page/palystore.png">
    </div>
    <div class="social">
      <h4>{{ 'FOOTER.FIND_US' | translate }}</h4>
      <img alt="" ngSrc="assets/facebook.svg" height="24" width="24">&nbsp;
      <img alt="" ngSrc="assets/twitter.svg" height="24" width="24">
    </div>
    <div class="shortcuts">
      @if (!isLogged) {
        <p [routerLink]="'/'">{{ 'FOOTER.SHORTCUT_HOME' | translate }}</p>
      }
      <p [queryParams]="{type:'NOTICE'}" [routerLink]="'/public/posts'">{{ 'FOOTER.SHORTCUT_NOTICES' | translate }}</p>
      <p class="disabledNav" [queryParams]="{type:'EVENT'}"
         [routerLink]="'/public/posts'">{{ 'FOOTER.SHORTCUT_EVENTS' | translate }}</p>
      <p class="disabledNav" [queryParams]="{type:'JOB'}"
         [routerLink]="'/public/posts'">{{ 'FOOTER.SHORTCUT_JOBS' | translate }}</p>
    </div>
    <div class="terms">
      <p><u [queryParams]="{type:TERMS_CONDITIONS , ft: true}"
            [routerLink]="'/public/settings/terms-conditions'">{{ 'FOOTER.TERMS_AND_CONDITION' | translate }}</u></p>
      <p><u [queryParams]="{type:PRIVACY_POLICY , ft: true}"
            [routerLink]="'/public/settings/privacy-policy'">{{ 'FOOTER.PRIVACY_POLICY' | translate }}</u></p>
    </div>
    <h3 class="copyright">
      {{ 'FOOTER.COPYRIGHT' | translate }} Version {{ version }}
    </h3>
  </div>

</div>

