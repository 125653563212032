<div class="landing container">
  <div [ngClass]="isBottom ? 'bottom' :'top'" class="header">
    <div class="company-info">
      <img alt="" class="icon" loading="lazy" ngSrc="assets/icons/LNLogo.svg" height="50" width="50">
      <div class="title">
        {{ 'HEADER.COMPANY_NAME' | translate }}
      </div>
    </div>
    <button [routerLink]="'public/auth/login'" class="signin">{{ 'HEADER.SIGN_IN_BUTTON' | translate }}</button>
  </div>

  <div class="content">

    <h1>{{ 'VISITOR.TITLE' | translate }}</h1>
    <p class="mobile-title">{{ 'VISITOR.SUB_TITLE' | translate }}</p>

    <div class="btn-row">

      <a [queryParams]="{type:commonTypes.NOTICE}" [routerLink]="'/public/posts'" class="custom-a" href="">
        <button>
          <img alt="" src="assets/landing-page/Iconly_Light_Document (1).svg">
          <span>{{ 'VISITOR.TAB_BUTTON_NOTICES' | translate }}</span>
        </button>
      </a>

      <a [queryParams]="{type:commonTypes.JOB}" [routerLink]="'/public/posts'" class="custom-a disabledNav" href="">
        <button>
          <img alt="" src="assets/landing-page/Iconly_Light_Bag 4.svg">
          <span>{{ 'VISITOR.TAB_BUTTON_JOBS' | translate }}</span>
        </button>
      </a>

      <a [queryParams]="{type:commonTypes.EVENT}" [routerLink]="'/public/posts'" class="custom-a disabledNav" href="">
        <button>
          <img alt="" src="assets/landing-page/Iconly_Light_Calendar (1).svg">
          <span>{{ 'VISITOR.TAB_BUTTON_EVENTS' | translate }}</span>
        </button>
      </a>

    </div>

    <div class="posters-wrapper">
      <div class="box">
        <div class="description">
          <h2>{{ 'VISITOR.POST_NOTICES_TITLE' | translate }}</h2>
          <p>{{ 'VISITOR.POST_NOTICES_DESCRIPTION' | translate }}</p>
          <button (click)="redirectToAdmin()">{{ 'VISITOR.REGISTER_CITY_BUTTON' | translate }}<img alt=""
                                                                                                   src="assets/icons/Arrow-Right.svg">
          </button>
        </div>
        <div class="image">
          <img alt="" src="assets/landing-page/image4.png">
        </div>
      </div>

      <div class="free-ads">
        <h2 class="title">{{ 'VISITOR.FREE_ADS'|translate }}</h2>
        <img alt="" class="image" loading="lazy" src="assets/landing-page/Free.svg">
      </div>

      <div class="box features-box-tab">

        <ol>
          <li>{{ 'VISITOR.FEATURE_ONE' | translate }}</li>
          <li>{{ 'VISITOR.FEATURE_TWO' | translate }}</li>
          <li>{{ 'VISITOR.FEATURE_THREE' | translate }}</li>
          <li>{{ 'VISITOR.FEATURE_FOUR' | translate }}</li>
          <li>{{ 'VISITOR.FEATURE_FIVE' | translate }}</li>
        </ol>

        <div class="features-box">
          <div class="Rectangle-141">
                        <span class="Unregistered-User">
                            {{ 'VISITOR.UNREGISTERED_USER' | translate }}
                        </span>
            <ol>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Cross.svg" height="18" width="18">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
            </ol>
          </div>


          <div class="Rectangle-141">
                        <span class="Unregistered-User">
                             {{ 'VISITOR.REGISTERED_USER' | translate }}
                        </span>
            <ol>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
              <li>
                <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
              </li>
            </ol>
          </div>

        </div>
      </div>
      <div [routerLink]="'/public/auth/register'" class="register-now">
        <button> {{ 'VISITOR.REGISTER_BUTTON' | translate }} <img alt="" ngSrc="assets/icons/Arrow-Right.svg"
                                                                  height="24" width="24"></button>
      </div>
    </div>

    <div class="mobile-post-wrapper">
      <div class="content-description">
        <div class="image">
          <img alt="" loading="lazy" src="assets/landing-page/image4.png" srcset="">
        </div>
        <div class="info">
          <h3>{{ 'VISITOR.POST_NOTICES_TITLE' | translate }}</h3>
          <p>
            {{ 'VISITOR.POST_NOTICES_DESCRIPTION' | translate }}
          </p>
          <div class="register-city">
            <button (click)="redirectToAdmin()">{{ 'VISITOR.REGISTER_CITY_BUTTON' | translate }} <img alt=""
                                                                                                      ngSrc="assets/icons/Arrow-Right.svg"
                                                                                                      height="24"
                                                                                                      width="24">
            </button>
          </div>
        </div>
      </div>
    </div>


    <div class="mobile-feature">
      <div class="content-feature">
        <div class="col-1">
          <p> {{ 'VISITOR.UNREGISTERED_USER' | translate }}</p>
          <ol>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Cross.svg" height="18" width="18">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
          </ol>
        </div>
        <div class="col-2">
          <ol>
            <li>{{ 'VISITOR.FEATURE_ONE' | translate }}</li>
            <li>{{ 'VISITOR.FEATURE_TWO' | translate }}</li>
            <li>{{ 'VISITOR.FEATURE_THREE' | translate }}</li>
            <li>{{ 'VISITOR.FEATURE_FOUR' | translate }}</li>
            <li>{{ 'VISITOR.FEATURE_FIVE' | translate }}</li>
          </ol>
        </div>
        <div class="col-1">
          <p>{{ 'VISITOR.REGISTERED_USER' | translate }}</p>
          <ol>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Cross.svg" height="18" width="18">
            </li>
            <li>
              <img alt="" ngSrc="assets/icons/Icons_Check.svg" height="15" width="22">
            </li>
          </ol>
        </div>
      </div>

      <div class="free-ads">
        <h2 class="title">{{ 'VISITOR.FREE_ADS'|translate }}</h2>
        <img alt="" class="image" loading="lazy" src="assets/landing-page/Free.svg">
      </div>

    </div>


    <div class="mobile-download">
      <h4>{{ 'VISITOR.DOWNLOAD_APP' | translate }}</h4>
      <div class="icon">
        <img alt="" src="assets/landing-page/appstore.png">
        &nbsp;
        &nbsp;
        &nbsp;
        <img alt="" src="assets/landing-page/palystore.png">
      </div>


      <div [routerLink]="'/public/auth/register'" class="register-now">
        <button> {{ 'VISITOR.REGISTER_BUTTON' | translate }}  <img alt="" ngSrc="assets/icons/Arrow-Right.svg"
                                                                   height="24" width="24"></button>
      </div>
    </div>


    <div class="footer">
      <div class="company-info">
        <h4>{{ 'FOOTER.TITLE' | translate }}</h4>
        <p>{{ 'FOOTER.CONTACT_EMAIL_ADDRESS' | translate }} &nbsp;&nbsp; {{ 'FOOTER.CONTACT_NO' | translate }}</p>
        <p>{{ 'FOOTER.ADDRESS' | translate }}, {{ 'FOOTER.CITY' | translate }},{{ 'FOOTER.STATE' | translate }}</p>
      </div>
      <div class="apps">
        <h4>{{ 'FOOTER.DOWNLOAD_APP' | translate }}</h4>
        <img alt="" src="assets/landing-page/appstore.png">&nbsp;
        <img alt="" src="assets/landing-page/palystore.png">
      </div>
      <div class="social">
        <h4>{{ 'FOOTER.FIND_US' | translate }}</h4>
        <img alt="" ngSrc="assets/facebook.svg" height="24" width="24">&nbsp;
        <img alt="" ngSrc="assets/twitter.svg" height="24" width="24">
      </div>
      <div class="shortcuts">
        <p [routerLink]="'/'">{{ 'FOOTER.SHORTCUT_HOME' | translate }}</p>
        <p [queryParams]="{type:'NOTICE'}"
           [routerLink]="'/public/posts'">{{ 'FOOTER.SHORTCUT_NOTICES' | translate }}</p>
        <p class="disabledNav" [queryParams]="{type:'EVENT'}"
           [routerLink]="'/public/posts'">{{ 'FOOTER.SHORTCUT_EVENTS' | translate }}</p>
        <p class="disabledNav" [queryParams]="{type:'JOB'}"
           [routerLink]="'/public/posts'">{{ 'FOOTER.SHORTCUT_JOBS' | translate }}</p>
      </div>
      <div class="terms">
        <p><u [queryParams]="{type:TERMS_CONDITIONS , ft: true}"
              [routerLink]="'/public/settings/terms-conditions'">{{ 'FOOTER.TERMS_AND_CONDITION' | translate }}</u></p>
        <p><u [queryParams]="{type:PRIVACY_POLICY , ft: true}"
              [routerLink]="'/public/settings/privacy-policy'">{{ 'FOOTER.PRIVACY_POLICY' | translate }}</u></p>
      </div>
      <h3 class="copyright">
        {{ 'FOOTER.COPYRIGHT' | translate }} Version {{ version }}
      </h3>
    </div>


  </div>
</div>

