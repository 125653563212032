import {Component, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {CommonTypes} from "../../utilities/models";
import {environment} from "../../../environments/environment";
import {DataService} from "../../services/data.service";
import {isPlatformBrowser, NgClass, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service ";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  imports: [
    NgClass,
    TranslateModule,
    RouterLink,
    NgOptimizedImage
  ],
  standalone: true
})
export class LandingComponent {

  isBottom!: boolean;

  commonTypes = CommonTypes;
  TERMS_CONDITIONS = CommonTypes.TERMS_CONDITIONS;
  PRIVACY_POLICY = CommonTypes.PRIVACY_POLICY;
  version: string = environment.version;

  constructor(private meta: Meta, private title: Title,
              @Inject(PLATFORM_ID) private platformId: object,
              private localStorageService: LocalStorageService,
              private dataService: DataService) {
    this.isBottom = false;
    this.meta.addTags([
      {name: 'description', content: 'Public Notices'},
      {name: 'author', content: 'PublicNotices'},
      {name: 'keywords', content: 'PN, Public Notices'}
    ]);
    this.title.setTitle('Public Notices');
    this.localStorageService.removeItem('_filter_');

    let activeLang = this.localStorageService.getItem('ACTIVE_LANG');
    if (activeLang !== null) {
      this.dataService.changeLang(activeLang);
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    }
  }

  redirectToAdmin() {
    let element = document.createElement('a');
    element.href = environment.adminUrl + 'auth/register';
    element.target = '_blank';
    element.click();
  }

}
